<template>
  <div style="padding:16px"v-loading="loading">
    <h3 style="margin-bottom:50px">{{ detail.title }}</h3>
    <h4>工单信息</h4>
    <div class="fielList">
      <p><span>工单号：</span>{{ detail.CTicketId || '--' }}</p>
      <p><span>被催办工单号：</span>{{ detail.TicketNo || '--' }}</p>
      <p><span>机房：</span>{{ detail.BizAreaName || '--' }}</p>
      <p><span>催办原因：</span>{{ detail.RushBecause || '--' }}</p>
      <p><span>催办类型：</span>{{ detail.TicketType || '--' }}</p>
      <p><span>催办人：</span>{{ detail.Creator || '--' }}</p>
      <p><span>当前处理人：</span>{{ detail.Handler || '--' }}</p>
      <p><span>催办时间：</span>{{ detail.CEntryTime || '--' }}</p>
      <p><span>催办状态：</span>{{ detail.ticket_status || '--' }}</p>
      <p><span>是否超时：</span>{{ detail.IsDelay || '--' }}</p>
      <p><span>催办完成时间：</span>{{ detail.CEndTime || '--' }}</p>
      <p><span>任务实际处理人：</span>{{ detail.CUser || '--' }}</p>
    </div>
    <h4>消息</h4>
    <p><span>催办内容：</span>{{ detail.RushContent || '--' }}</p>
    <p><span>回复消息：</span>{{ detail.Message || '--' }}</p>
  </div>
</template>

<script>
import { networkRushTicketDetail } from '@/api/monitor';
export default {
  name: 'networkRushTicketDetail',
  data() {
    return {
      loading: false,
      detail: {},
    };
  },
  created() {
    this.networkRushTicketDetail();
  },
  methods: {
    //详情接口
    networkRushTicketDetail() {
      this.loading = true;
      console.log(this.$route)
      console.log(this.$route.query)
      networkRushTicketDetail({CTicketId:this.$route.query.CTicketId})
        .then((res) => {
          console.log(res.data.data)
          this.detail = res.data.data[0];
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.fielList1{
   p {
    font-size: 14px;
    line-height: 18px;
    margin: 0 80px 16px 0;
    padding: 0 !important;
    color: #333;
    span {
      color: #666;
    }
  }
}
.fielList {
  display: flex;
  flex-wrap: wrap;
  p {
    font-size: 14px;
    line-height: 18px;
    margin: 0 80px 10px 0;
    padding: 0 !important;
    color: #333;
    span {
      color: #666;
    }
  }
}
</style>
