var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticStyle: { padding: "16px" }
    },
    [
      _c("h3", { staticStyle: { "margin-bottom": "50px" } }, [
        _vm._v(_vm._s(_vm.detail.title))
      ]),
      _c("h4", [_vm._v("工单信息")]),
      _c("div", { staticClass: "fielList" }, [
        _c("p", [
          _c("span", [_vm._v("工单号：")]),
          _vm._v(_vm._s(_vm.detail.CTicketId || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("被催办工单号：")]),
          _vm._v(_vm._s(_vm.detail.TicketNo || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("机房：")]),
          _vm._v(_vm._s(_vm.detail.BizAreaName || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("催办原因：")]),
          _vm._v(_vm._s(_vm.detail.RushBecause || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("催办类型：")]),
          _vm._v(_vm._s(_vm.detail.TicketType || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("催办人：")]),
          _vm._v(_vm._s(_vm.detail.Creator || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("当前处理人：")]),
          _vm._v(_vm._s(_vm.detail.Handler || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("催办时间：")]),
          _vm._v(_vm._s(_vm.detail.CEntryTime || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("催办状态：")]),
          _vm._v(_vm._s(_vm.detail.ticket_status || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("是否超时：")]),
          _vm._v(_vm._s(_vm.detail.IsDelay || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("催办完成时间：")]),
          _vm._v(_vm._s(_vm.detail.CEndTime || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("任务实际处理人：")]),
          _vm._v(_vm._s(_vm.detail.CUser || "--"))
        ])
      ]),
      _c("h4", [_vm._v("消息")]),
      _c("p", [
        _c("span", [_vm._v("催办内容：")]),
        _vm._v(_vm._s(_vm.detail.RushContent || "--"))
      ]),
      _c("p", [
        _c("span", [_vm._v("回复消息：")]),
        _vm._v(_vm._s(_vm.detail.Message || "--"))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }